<template>
  <div :class="$style.root">
    <VStepper alt-labels non-linear elevation="0">
      <VStepperHeader>
        <VDivider />
        <template v-for="({id, name, status, code, reviewer, date, possibleReviewers}, index) in items">
          <VDivider v-if="!!index" :key="index + code" />
          <template v-if="code === statuses['NO']">
            <VStepperStep :step="index" error-icon="mdi-close" :rules="[() => false]" :key="index">
              <div class="text-center">{{name}}</div>
              <small class="mt-2">{{status}}</small>
              <div class="mt-2" v-if="(isReviewer || isSpectator || isGeneralReviewer) && reviewer">
                <small>{{reviewer}} {{date}}</small>
              </div>
            </VStepperStep>
          </template>
          <template v-if="code === statuses['REWORK']">
            <VStepperStep :step="index" complete-icon="mdi-pencil-outline" complete :key="index">
              <div class="text-center">{{name}}</div>
              <small class="mt-2">{{status}}</small>
              <div class="mt-2" v-if="(isReviewer || isSpectator || isGeneralReviewer) && reviewer">
                <small>{{reviewer}} {{date}}</small>
              </div>
            </VStepperStep>
          </template>
          <template v-if="code === statuses['YES']">
            <VStepperStep :step="index" complete color="success" :key="index">
              <div class="text-center">{{name}}</div>
              <small class="mt-2">{{status}}</small>
              <div class="mt-2" v-if="(isReviewer || isSpectator || isGeneralReviewer) && reviewer">
                <small>{{reviewer}} {{date}}</small>
              </div>
            </VStepperStep>
          </template>
          <template v-if="code === statuses['WAIT_IN_LINE']">
            <VStepperStep :step="index" complete-icon="mdi-clock-outline" complete color="rgba(0, 0, 0, 0.1)" :key="index">
              <div class="text-center">{{name}}</div>
              <small class="mt-2">{{status}}</small>
              <div class="mt-2" v-if="(isReviewer || isSpectator || isGeneralReviewer) && reviewer">
                <small>{{reviewer}} {{date}}</small>
              </div>
            </VStepperStep>
          </template>
          <template v-if="code === statuses['WAIT_FOR_DECISION']">
            <VStepperStep :step="index" complete-icon="mdi-clock-outline" complete color="rgba(0, 0, 0, 0.38)" :key="index">
              <div class="text-center">{{name}}</div>
              <small class="mt-2">{{status}}</small>
              <div class="mt-2" v-if="(isReviewer || isSpectator || isGeneralReviewer) && reviewer">
                <small>{{reviewer}}</small>
              </div>
              <div v-if="isGeneralReviewer && !!possibleReviewers.length" >
                <VBtn @click="onEditStep(id)" class="mt-2" small><VIcon v-text="'mdi-pencil-outline'" left/>Изменить</VBtn>
              </div>
            </VStepperStep>
          </template>
        </template>
        <VDivider />
      </VStepperHeader>
    </VStepper>
    <VDialog v-model="stepEditDialog" max-width="900" scrollable persistent>
      <VCard tile>
        <VToolbar flat dark color="primary">
          <VToolbarTitle class="px-2">Редактирование шага согласования</VToolbarTitle>
          <VSpacer/>
          <VBtn icon dark @click="onStepEditClose">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCardText class="pt-5">
          <VCardText>
            <StepEditForm :step="stepForEdit" @submit="onEditSubmit" />
          </VCardText>
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import {find, mapValues} from 'lodash-es';
import {mapActions, mapGetters} from 'vuex';
import { STATUSES } from '@/store/pkopnr/enums';
import { ROLES } from '@/store/user/enums';
import StepEditForm from '@/components/pkopnr/StepEditForm/StepEditForm.vue';

export default {
  name: 'Stepper',
  components: {StepEditForm},
  props: {
    items: { type: Array },
    id: { type: String },
  },
  data() {
    return {
      stepEditDialog: false,
      stepEditId: null,
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    statuses(){
      return mapValues(STATUSES, 'value');
    },
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkopnr');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkopnr');
    },
    isGeneralReviewer() {
      return this.hasRole(ROLES.GENERAL_REVIEWER, 'pkopnr');
    },
    stepForEdit() {
      return find(this.items, {id: this.stepEditId});
    }
  },
  methods: {
    ...mapActions({
      editApprovalTask: 'pkopnr/editApprovalTask',
      fetchCounterpartyDetail: 'pkopnr/fetchCounterpartyDetail',
    }),
    onEditStep: function (id) {
      this.stepEditDialog = true;
      this.stepEditId = id;
    },
    onStepEditClose: function () {
      this.stepEditDialog = false;
      this.stepEditId = null;
    },
    onEditSubmit: function ({ reviewerId }) {
      const id = this.stepEditId;
      this.editApprovalTask({ id, reviewerId }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Изменения сохранены',
        });
        this.onStepEditClose();
        this.fetchCounterpartyDetail({id: this.id});
      });
    },
  },
}
</script>

<style module lang="scss">
.root {}
</style>
