<template>
  <div :class="$style.root">
    <CounterpartyCard label="Отображение пунктов анкеты">
      <VRadioGroup v-model="simple" class="my-0" hide-details>
        <VRadio label="Все" :value="false" />
        <VRadio label="С комментариями проверяющего" :value="true" :disabled="(!hasActualComments && !hasDraftComments) || !isCommentsVisible" />
      </VRadioGroup>
    </CounterpartyCard>
    <VForm v-model="valid" lazy-validation ref="form" @submit.prevent="onSave">
      <VExpansionPanels flat multiple>
        <CounterpartyDetailSection
            v-for="(section, code) in filtered(fields)"
            v-bind="{...section}"
            :status="status"
            :readonly="readonly"
            :comments="comments"
            :hiddenKeys="hiddenKeys"
            :key="code"
            :errors="validationErrorsInSectionCount()"
            :forward="$refs.form"
            :pengind="pending"
            :code="code"
            :reviewer="reviewer"
        />
      </VExpansionPanels>
    </VForm>
    <VFooter v-if="!isSpectator && !isGeneralReviewer && (!readonly || waitDecision)" app>
      <VProgressLinear v-if="pending" absolute style="top: 0" indeterminate color="primary darken-2" />
      <div class="py-3 grow">
        <template v-if="!readonly">
          <VBtn color="primary" class="mr-2" depressed @click="onSave">Сохранить</VBtn>
          <VBtn color="secondary" class="mr-2" depressed :disabled="!diff" @click="onReset">Отменить</VBtn>
          <VBtn color="primary" class="float-right" depressed :disabled="diff || hasActualComments || !valid" @click="onAgreement">Отправить на согласование</VBtn>
        </template>
        <template v-if="waitDecision">
          <template v-if="hasReviewer">
            <VBtn color="primary" class="mr-2" depressed :disabled="!hasDraftComments" @click="onRework">Доработка</VBtn>
            <VBtn color="success" class="mr-2" depressed :disabled="hasDraftComments" @click="onYes">Согласовать</VBtn>
            <VBtn color="error" class="float-right" depressed @click="onNo">Отказать</VBtn>
          </template>
          <template v-else>
            <VBtn color="primary" depressed @click="onTakeToWork">Взять в работу</VBtn>
          </template>
        </template>
      </div>
    </VFooter>
    <VDialog v-model="dialog" max-width="900" scrollable persistent>
      <VCard tile>
        <VToolbar flat dark color="primary">
          <VToolbarTitle class="px-2">Решение по заявке</VToolbarTitle>
          <VSpacer/>
          <VBtn icon dark @click="dialog = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCardText class="pt-5">
          <VCardText>
            <DecisionForm :disabled="pending" :decision="decision" :need-files="needFilesInDecision" @submit="onSubmitDecision" />
          </VCardText>
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import {get, omit, isEmpty, pickBy, keys, find, includes, forEach, filter} from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkopnr/enums';
import CounterpartyCard from '@/components/pkopnr/CounterpartyCard/CounterpartyCard';
import CounterpartyDetailSection from '@/components/pkopnr/CounterpartyDetailSection/CounterpartyDetailSection';
import DecisionForm from '@/components/pkopnr/DecisionForm/DecisionForm';
export default {
  name: 'CounterpartyDetail',
  components: {
    CounterpartyCard,
    CounterpartyDetailSection,
    DecisionForm,
  },
  props: {
    id: { type: String },
    values: { type: Object },
    status: { type: String },
    comments: { type: Object },
    pending: { type: Boolean },
    reviewer: { type: String },
  },
  data() {
    return {
      valid: true,
      simple: false,
      fields: JSON.parse(JSON.stringify(this.values)),
      dialog: false,
      decision: null,
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      loading: 'pkopnr/pending',
    }),
    hasReviewer() {
      return Boolean(this.reviewer);
    },
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
    readonly() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER, ROLES.GENERAL_REVIEWER], 'pkopnr') ||  this.hasRole([ROLES.SPECTATOR], 'pkopnr') || this.status === get(STATUSES, 'ON_APPROVAL.value');
    },
    isCommentsVisible() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER, ROLES.GENERAL_REVIEWER], 'pkopnr') || (this.hasRole([ROLES.CONTRAGENT]) && this.status !== get(STATUSES, 'ON_APPROVAL.value'))
    },
    isSpectator() {
      return this.hasRole([ROLES.SPECTATOR], 'pkopnr');
    },
    isGeneralReviewer() {
      return this.hasRole([ROLES.GENERAL_REVIEWER], 'pkopnr');
    },
    waitDecision() {
      return this.status === get(STATUSES, 'WAIT_FOR_DECISION.value');
    },
    hasActualComments() {
      return !isEmpty(pickBy(this.comments, (comments) => find(comments, { actual: true })));
    },
    hasDraftComments() {
      return !isEmpty(pickBy(this.comments, (comments) => find(comments, { draft: true })));
    },
    questionsWithComments() {
      return keys(pickBy(this.comments, (comments) => find(comments, ({actual, draft}) => actual || draft)));
    },
    needFilesInDecision() {
      return this.hasRole([ROLES.LAWYER], 'pkopnr') && this.decision === get(STATUSES, 'YES.value');
    },
    hiddenKeys() {
      const func = (questions) => {
        const temp = [];
        forEach(questions, (question, key) => {
          // Скрыть вопросы без комментариев
          if (this.simple && !includes(this.questionsWithComments, key)) {
            temp.push(key); return;
          }
        });
        return temp;
      };
      const result = [];
      forEach(this.fields, ({ sections, questions }, key) => {
        forEach(sections, ({ questions }, key) => {
          let hide = func(questions);
          if (hide.length === keys(questions).length) result.push(key);
          result.push(...hide);
        });

        let hide = func(questions);
        result.push(...hide);
        if (hide.length === keys(questions).length && !find(keys(sections), key => !includes(result, key))) result.push(key);
      });
      return result;
    },
  },
  methods: {
    ...mapActions({
      sendDecision: 'pkopnr/sendDecision',
      updateCounterparty: 'pkopnr/updateCounterparty',
      agreementCounterparty: 'pkopnr/agreementCounterparty',
      fetchCommentList: 'pkopnr/fetchCommentList',
      fetchCounterpartyDetail: 'pkopnr/fetchCounterpartyDetail',
      updateReviewer: 'pkopnr/updateReviewer',
    }),
    filtered(items) {
      return omit(items, this.hiddenKeys);
    },
    onSave() {
      const id = this.id;
      const items = this.fields;
      this.updateCounterparty({ id, items }).then(result => {
        if (result) {
          this.fetchCommentList({ id });
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Ваша анкета сохранена, но не отправлена нажмите на кнопку в правом нижнем углу чтобы отправить на согласование',
          });
        }
      });
    },
    onAgreement() {
      const id = this.id;
      const valid = this.$refs.form.validate();
      if (valid) {
        this.agreementCounterparty({ id }).then((result) => {
          if (result) {
            this.$router.push({ name: 'pkopnr/CounterpartyListView' });
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Заявка отправлена на согласование',
            });
          }
        });
      }
    },
    onRework() {
      this.decision = get(STATUSES, 'REWORK.value');
      this.dialog = true;
    },
    onYes() {
      this.decision = get(STATUSES, 'YES.value');
      this.dialog = true;
    },
    onNo() {
      this.decision = get(STATUSES, 'NO.value');
      this.dialog = true;
    },
    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
    onSubmitDecision(comment, notation, expertMark, files) {
      const id = this.id;
      const decision = this.decision;
      this.sendDecision({ id, decision, comment, notation, expertMark, files }).then((result) => {
        if (result) {
          this.$router.push({ name: 'pkopnr/CounterpartyListView' });
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Решение по заявке принято',
          });
          this.dialog = false;
        }
      });
    },
    validationErrorsInSectionCount() {
      return filter(get(this.$refs, 'form.inputs'), { hasError: true }).length;
    },
    onTakeToWork: function () {
      const id = this.id;
      this.updateReviewer({ id }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка взята в работу',
        });
        // TODO: Сразу установить Reviewer в updateReviewer?
        this.fetchCounterpartyDetail({ id });
      })
    }
  },
  mounted() {
    this.$refs.form.validate();
  },
  watch: {
    values: {
      handler: function() {
        this.onReset();
      }
    },
    dialog: {
      handler: function (value) {
        if (!value)
          this.decision = null;
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
}
</style>
